import {GraphQLResult} from '@aws-amplify/api-graphql';
import {API, graphqlOperation} from 'aws-amplify';
import {useQuery} from 'react-query';
import {
  ListConsultingSheetsByOwnerIdQuery,
  ListConsultingSheetsByOwnerIdQueryVariables,
} from '../API';
import {useOAuthUser} from '../AuthProvider';
import {listConsultingSheetsByOwnerId} from '../graphql/queries';

export const useConsultingSheets = (userId?: string) => {
  const user = useOAuthUser();
  return useQuery(
    ['consultingSheets', user?.attributes.sub, userId],
    async () => {
      if (!user) {
        return undefined;
      }
      const variables: ListConsultingSheetsByOwnerIdQueryVariables = {
        ownerId: userId ?? user.attributes.sub,
        limit: 10000,
      };
      const result = (await API.graphql(
        graphqlOperation(listConsultingSheetsByOwnerId, variables),
      )) as GraphQLResult<ListConsultingSheetsByOwnerIdQuery>;
      return result?.data?.listConsultingSheetsByOwnerId;
    },
  );
};
