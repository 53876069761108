import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PeopleIcon from '@mui/icons-material/People';
import {Grid, Stack, Typography} from '@mui/material';
import axios from 'axios';
import {convert, stringToCode} from 'encoding-japanese';
import jsonExport from 'jsonexport/dist';
import React, {useCallback, useMemo, useState} from 'react';
import {
  Resource,
  Datagrid,
  DateField,
  List,
  BooleanField,
  Layout,
  LayoutProps,
  AppBar,
  Admin,
  ListProps,
  TextField,
  downloadCSV,
  useRecordContext,
} from 'react-admin';
import {buildAuthProvider, buildDataProvider} from 'react-admin-amplify';
import {useNavigate} from 'react-router-dom';
import {PdfCategory} from '../API';
import awsExports from '../aws-exports';
import {BaseBox} from '../components/BaseBox';
import {Button} from '../components/Button';
import {LabelTitle} from '../components/LabelTitle';
import {TextField as CustomTextField} from '../components/TextField';
import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';
import {useConsultingSheets} from '../queries/consultingSheet';
import {useDownloadPdf} from '../queries/download';
import {useUsers} from '../queries/user';
import {isNonNull} from '../utils/isNonNull';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const exporter = (data: any[]) => {
  jsonExport(data, (_err, csv) => {
    const unicodeArray = stringToCode(csv);
    const shiftJisArray = convert(unicodeArray, {
      to: 'SJIS',
      from: 'UNICODE',
    });
    const csvOfShiftJis = new Uint8Array(shiftJisArray);
    downloadCSV(csvOfShiftJis, 'download');
  });
};

const ConsultingSheetDownloadButtonField = () => {
  const {mutate: downloadPdf, isLoading} = useDownloadPdf();
  const record = useRecordContext();
  const {data: consultingSheets} = useConsultingSheets(record.id as string);
  const disabled = useMemo(() => {
    return !record.id || consultingSheets?.items.length === 0;
  }, [consultingSheets?.items.length, record.id]);
  const handleClick = useCallback(() => {
    if (!consultingSheets) return;
    if (!consultingSheets.items) return;
    if (!consultingSheets.items.length) return;
    downloadPdf(
      {
        category: PdfCategory.CONSULTINGSHEET,
        fileName: `${consultingSheets.items[0]?.currentDate}_${record.firstName}${record.lastName}様_コンサルティングシート`,
        userName: `${record.firstName} ${record.lastName}`,
        consultingData: consultingSheets.items.filter(isNonNull),
      },
      {
        onError: () => {
          return;
        },
      },
    );
  }, [consultingSheets, downloadPdf, record.firstName, record.lastName]);
  if (disabled) {
    return null;
  }
  return (
    <Button onClick={handleClick} size="small">
      {isLoading ? 'ダウンロード中' : 'ダウンロード'}
    </Button>
  );
};

const UserList = (props: ListProps) => {
  return (
    <List title="UserList" exporter={exporter} {...props}>
      <Datagrid bulkActionButtons={false}>
        <DateField source="createdAt" label="作成日" sortable={true} />
        <TextField source="id" label="ユーザーID" sortable={false} />
        <TextField source="email" label="メールアドレス" sortable={false} />
        <TextField source="lastName" label="姓" sortable={false} />
        <TextField source="firstName" label="名" sortable={false} />
        <TextField
          source="plan"
          label="プラン"
          emptyText={'LIGHT'}
          sortable={false}
        />
        <BooleanField source="disabled" label="退会" sortable={false} />
        <ConsultingSheetDownloadButtonField />
      </Datagrid>
    </List>
  );
};

const MailDelivery = () => {
  const navigate = useNavigate();
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [isCompleted, setIsCompleted] = useState(false);
  const {data} = useUsers();

  const onSubmit = useCallback(async () => {
    if (!data) return;
    await Promise.all(
      data.items.filter(isNonNull).map(user => {
        if (!user.email) return false;
        return axios.post('https://api.freescorp.com/contact', {
          ToAddresses: user.email,
          Subject: subject,
          Message: body,
        });
      }),
    );
    setIsCompleted(true);
  }, [body, data, subject]);

  return (
    <Stack spacing={3} mt="64px">
      <BaseBox sx={{wordBreak: 'break-all', borderRadius: '4px'}}>
        {isCompleted ? (
          <Stack spacing={3}>
            <Typography sx={{fontSize: '20px', fontWeight: 'bold'}}>
              メール送信が完了しました。
            </Typography>
            <Button variant="contained" onClick={() => navigate('/')}>
              TOP
            </Button>
          </Stack>
        ) : (
          <>
            <Grid
              container
              direction="row"
              alignItems="center"
              spacing={3}
              sx={{marginBottom: '20px'}}>
              <Grid
                item
                container
                spacing={1}
                direction="row"
                alignItems="center">
                <Grid item xs={3}>
                  <LabelTitle isRequired>タイトル</LabelTitle>
                </Grid>
                <Grid item xs={9}>
                  <CustomTextField
                    defaultValue={subject}
                    onChange={event => setSubject(event.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={1} direction="row">
                <Grid item xs={3}>
                  <LabelTitle isRequired>本文</LabelTitle>
                </Grid>
                <Grid item xs={9}>
                  <CustomTextField
                    defaultValue={body}
                    onChange={event => setBody(event.target.value)}
                    multiline
                    minRows={5}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Button
              disabled={!subject || !body}
              variant="contained"
              sx={{display: 'flex', m: '24px 0 0 auto'}}
              onClick={onSubmit}>
              送信する
            </Button>
          </>
        )}
      </BaseBox>
    </Stack>
  );
};

const authProvider = buildAuthProvider({
  authGroups: ['Admins'],
});

const dataProvider = buildDataProvider(
  {
    queries,
    mutations,
  },
  {
    storageBucket: awsExports.aws_user_files_s3_bucket,
    storageRegion: awsExports.aws_user_files_s3_bucket_region,
    enableAdminQueries: true,
  },
);

const CustomizedAppBar = () => (
  <AppBar
    sx={{
      '& .RaAppBar-toolbar': {
        display: 'none',
      },
    }}></AppBar>
);

const CustomizedLayout = (props: LayoutProps) => (
  <Layout {...props} appBar={CustomizedAppBar} />
);

export const AdminScreen = () => {
  return (
    <Admin
      layout={CustomizedLayout}
      basename="/admin"
      authProvider={authProvider}
      dataProvider={dataProvider}>
      <Resource
        name="users"
        options={{label: 'ユーザー一覧'}}
        list={UserList}
        icon={PeopleIcon}
      />
      <Resource
        name="mailDelivery"
        options={{label: 'メール配信'}}
        list={MailDelivery}
        icon={MailOutlineIcon}
      />
    </Admin>
  );
};
